import { useState, useEffect, memo, useCallback, useRef, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { propertyAPI } from '../../services/api'
import { useFavorites } from '../../hooks/useFavorites'
import { useAuth } from '../../contexts/AuthContext'
import PropertyFilters from './PropertyFilters'
import { FiHeart, FiMaximize, FiChevronLeft, FiChevronRight, FiX, FiFilter, FiArrowRight, FiHome } from 'react-icons/fi'
import { IoBedOutline, IoWaterOutline, IoLocationOutline, IoHomeOutline, IoBusinessOutline, IoStorefrontOutline, IoEarthOutline } from "react-icons/io5"
import { BiBuildingHouse } from "react-icons/bi"
import React from 'react';
import { toast } from 'react-hot-toast';
import { optimizeImage, generateBlurHash } from '../../utils/imageOptimizer';
import SARPrice from '../shared/SARPrice';


const content = {
    en: {
        title: 'Available Properties',
        showFilters: 'Show Filters',
        hideFilters: 'Hide Filters',
        noProperties: 'No properties available',
        noMatches: 'No properties found matching your search criteria',
        tryAdjusting: 'Try adjusting your filters or',
        resetAll: 'reset all filters',
        filters: {
            type: 'Property Type',
            price: 'Price Range',
            area: 'Area Range',
            location: 'Location',
            bedrooms: 'Bedrooms',
            bathrooms: 'Bathrooms',
            features: 'Features'
        },
        propertyTypes: {
            apartment: 'Apartment',
            villa: 'Villa',
            office: 'Office',
            shop: 'Shop',
            land: 'Land'
        },
        currency: 'SAR',
        sqm: 'm²',
        apply: 'Apply Filters',
        reset: 'Reset Filters',
        from: 'From',
        viewDetails: 'View Details',
        amenities: {
            'parking': 'Parking',
            'swimming pool': 'Swimming Pool',
            'gym': 'Gym',
            '24/7 security': '24/7 Security',
            'elevator': 'Elevator',
            'garden': 'Garden',
            'central ac': 'Central AC',
            'balcony': 'Balcony',
            'maid\'s room': 'Maid\'s Room',
            'storage room': 'Storage Room',
            'kitchen appliances': 'Kitchen Appliances',
            'internet': 'Internet',
            'satellite/cable tv': 'Satellite/Cable TV',
            'intercom': 'Intercom',
            'maintenance': 'Maintenance',
            'nearby mosque': 'Nearby Mosque',
            'shopping centers': 'Shopping Centers',
            'schools nearby': 'Schools Nearby',
            'pets allowed': 'Pets Allowed',
            'sea view': 'Sea View',
            'city view': 'City View',
            'garden view': 'Garden View',
            'street view': 'Street View',
            'mall view': 'Mall View'
        }
    },
    ar: {
        title: 'العقارات المتاحة',
        showFilters: 'إظهار الفلاتر',
        hideFilters: 'إخفاء الفلاتر',
        noProperties: 'لا تجد عقارات متاحة',
        noMatches: 'لا توجد عقارات تطابق معايير البحث',
        tryAdjusting: 'حاول تعديل الفلاتر أو',
        resetAll: 'إعادة تعيين جميع الفلاتر',
        filters: {
            type: 'نوع العقار',
            price: 'نطاق السعر',
            area: 'نطاق المساحة',
            location: 'الموقع',
            bedrooms: 'غرف النوم',
            bathrooms: 'دورات المياه',
            features: 'المميزات'
        },
        propertyTypes: {
            apartment: 'شقة',
            villa: 'فيلا',
            office: 'مكتب',
            shop: 'محل',
            land: 'أرض'
        },
        currency: 'ريال',
        sqm: 'م²',
        apply: 'تطبيق الفلاتر',
        reset: 'إعادة تعيين',
        from: 'من',
        viewDetails: 'عرض التفاصيل',
        amenities: {
            'parking': 'موقف سيارات',
            'swimming pool': 'مسبح',
            'gym': 'صالة رياضية',
            '24/7 security': 'حراسة أمنية 24/7',
            'elevator': 'مصعد',
            'garden': 'حديقة',
            'central ac': 'تكييف مركزي',
            'balcony': 'شرفة',
            'maid\'s room': 'غرفة خادمة',
            'storage room': 'غرفة تخزين',
            'kitchen appliances': 'أجهزة مطبخ',
            'internet': 'إنترنت',
            'satellite/cable tv': 'قنوات فضائية/تلفاز',
            'intercom': 'اتصال داخلي',
            'maintenance': 'صيانة',
            'nearby mosque': 'مسجد قريب',
            'shopping centers': 'مراكز تسوق قريبة',
            'schools nearby': 'مدارس قريبة',
            'pets allowed': 'يسمح بالحيوانات الأليفة',
            'sea view': 'إطلالة بحرية',
            'city view': 'إطلالة على المدينة',
            'garden view': 'إطلالة على الحديقة',
            'street view': 'إطلالة على الشارع',
            'mall view': 'إطلالة على المول'
        }
    }
};

// Update the getOptimizedImageUrl function
const getOptimizedImageUrl = (url, size = 'medium') => {
    if (!url) return 'https://placehold.co/600x400?text=No+Image';
    if (url.includes('placehold.co')) return url;

    const sizes = {
        thumbnail: 200,
        small: 400,
        medium: 600,
        large: 800
    };

    return optimizeImage(url, {
        width: sizes[size],
        quality: size === 'thumbnail' ? 60 : 80,
        format: 'webp'
    });
};

// Memoize formatAddress function
const formatAddress = (address) => {
    if (!address) return '';

    // Check if the address is in Arabic or English
    const isArabic = /[\u0600-\u06FF]/.test(address);

    // Split by appropriate delimiter
    const parts = isArabic ? address.split('،') : address.split(',');

    // Clean and filter parts
    const cleanParts = parts.map(part => part.trim()).filter(Boolean);

    // Function to identify if a part is likely a city or neighborhood
    const isLocationPart = (part) => {
        // Skip parts that are:
        if (
            // Plus codes
            /^[A-Z0-9]{4,}\+[A-Z0-9]{3,}/.test(part) ||
            // Postal codes
            /^\d{5,}$/.test(part) ||
            // Country names
            /saudi arabia|المملكة العربية السعودية|السعودية|ksa/i.test(part)
        ) {
            return false;
        }
        return true;
    };

    // Filter to keep only location parts and clean them
    const locationParts = cleanParts
        .filter(isLocationPart)
        .map(part => {
            // Remove postal codes that might be at the end of a part
            part = part.replace(/\s+\d{5,}$/, '');

            // For Arabic addresses, ensure حي is only included if it's part of the actual name
            if (isArabic) {
                if (part.startsWith('حي ')) {
                    // If the neighborhood name includes حي as part of its name (like حي السرحية)
                    // keep it, otherwise remove the prefix
                    const withoutPrefix = part.replace(/^حي\s+/, '');
                    return withoutPrefix;
                }
            }

            // For English addresses, clean up district/area prefixes
            if (!isArabic) {
                part = part.replace(/^(district|area|neighborhood)\s*/i, '');
            }

            return part.trim();
        })
        .filter(Boolean); // Remove any empty parts after cleaning

    // If we have multiple parts, take the last two (usually neighborhood and city)
    // If we have only one part, use it as is
    const significantParts = locationParts.length > 2
        ? locationParts.slice(-2)
        : locationParts;

    // Join with the appropriate delimiter based on the original language
    return significantParts.join(isArabic ? '، ' : ', ');
};

// Update the OptimizedImage component
const OptimizedImage = memo(({ src, alt, onLoad, onError, className, priority = false }) => {
    const [isLoading, setIsLoading] = useState(true);
    const blurUrl = useMemo(() => generateBlurHash(src), [src]);

    return (
        <div className={`relative ${className || ''}`}>
            {isLoading && (
                <img
                    src={blurUrl}
                    alt={alt}
                    className="absolute inset-0 w-full h-full object-cover blur-sm"
                    aria-hidden="true"
                />
            )}
            <img
                src={src}
                alt={alt}
                className={`${className || ''} ${isLoading ? 'opacity-0' : 'opacity-100'} transition-opacity duration-300`}
                loading={priority ? 'eager' : 'lazy'}
                decoding={priority ? 'sync' : 'async'}
                onLoad={(e) => {
                    setIsLoading(false);
                    onLoad?.(e);
                }}
                onError={(e) => {
                    setIsLoading(false);
                    onError?.(e);
                }}
            />
        </div>
    );
});

OptimizedImage.displayName = 'OptimizedImage';

// Enhance the PropertyCard component
const PropertyCard = memo(({
    property,
    language,
    onClick,
    onLoginRequired,
    index
}) => {
    const t = content[language];
    const isInitialLoad = index < 6;
    const { isFavorited, toggleFavorite } = useFavorites(language);
    const isFav = isFavorited(property.id);
    const [imageLoaded, setImageLoaded] = useState(false);
    const { user } = useAuth();
    const isOwner = user?.type === 'owner';

    const handleFavorite = async (e) => {
        e.stopPropagation();
        const token = localStorage.getItem('token');
        if (!token) {
            onLoginRequired();
            return;
        }
        toggleFavorite({ propertyId: property.id, isFavorite: isFav });
    };

    // Split the component into two parts for better performance
    const PropertyInfo = (
        <div className="p-4">
            <div className="space-y-2 mb-4">
                <div className="flex flex-col">
                    <h3 className="font-semibold text-lg line-clamp-2 group-hover:text-[#BE092B] transition-colors">
                        {property.title}
                    </h3>
                    <div className="flex items-center gap-1.5 mt-2">
                        <span className="text-gray-600 text-sm flex items-center gap-1.5">
                            {getPropertyTypeIcon(property.type)}
                            {getPropertyTypeLabel(property.type, t)}
                        </span>
                    </div>
                </div>
                {property.address && (
                    <div className="flex items-center gap-2 text-gray-600">
                        <IoLocationOutline className="text-[#BE092B] flex-shrink-0 w-4 h-4" />
                        <span className="text-sm line-clamp-1">{formatAddress(property.address)}</span>
                    </div>
                )}
            </div>

            <div className="grid grid-cols-3 gap-2 mb-4 py-3 border-y border-gray-100">
                {property.number_bedroom > 0 && (
                    <div className="flex items-center gap-1.5">
                        <IoBedOutline className="text-[#BE092B] w-4 h-4" />
                        <span className="text-sm text-gray-600">{property.number_bedroom}</span>
                    </div>
                )}
                {property.number_bathroom > 0 && (
                    <div className="flex items-center gap-1.5">
                        <IoWaterOutline className="text-[#BE092B] w-4 h-4" />
                        <span className="text-sm text-gray-600">{property.number_bathroom}</span>
                    </div>
                )}
                {property.area && (
                    <div className="flex items-center gap-1.5">
                        <FiMaximize className="text-[#BE092B] w-4 h-4" />
                        <span className="text-sm text-gray-600">{property.area} m²</span>
                    </div>
                )}
            </div>

            <div className="flex justify-between items-center">
                <div>
                    <span className="text-gray-500 text-xs block mb-0.5">{t.from}</span>
                    <p className="text-[#BE092B] font-bold text-xl">
                        <SARPrice
                            amount={property.price}
                            showSymbolBefore={language !== 'ar'}
                        />
                        {language === 'ar' ? ' سنوياً' : ' / year'}
                    </p>
                </div>
                <button className="px-4 py-2 bg-[#BE092B]/90 text-white rounded-lg hover:bg-[#8a1328] transition-colors flex items-center gap-2 text-sm group-hover:bg-[#8a1328]">
                    {t.viewDetails}
                    <FiArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
                </button>
            </div>
        </div>
    );

    const PropertyImage = (
        <div className="relative aspect-[4/3] overflow-hidden bg-gray-100">
            <div
                className={`absolute inset-0 transition-opacity duration-300 ${imageLoaded ? 'opacity-0' : 'opacity-100'}`}
                style={{
                    backgroundImage: `url(${generateBlurHash(property.images?.[0]?.url)})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    filter: 'blur(10px)',
                    transform: 'scale(1.1)',
                }}
            />
            <img
                src={getOptimizedImageUrl(property.images?.[0]?.url, isInitialLoad ? 'small' : 'thumbnail')}
                alt={property.title}
                className={`w-full h-full object-cover transform group-hover:scale-105 transition-all duration-300 ${imageLoaded ? 'opacity-100' : 'opacity-0'}`}
                loading="lazy"
                decoding="async"
                onLoad={() => setImageLoaded(true)}
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-transparent to-transparent" />
            {!isOwner && (
                <button
                    onClick={handleFavorite}
                    className="absolute top-3 right-3 p-2 bg-white/90 hover:bg-white rounded-full transition-all shadow-lg group z-10"
                    aria-label={isFav ? 'Remove from favorites' : 'Add to favorites'}
                >
                    <FiHeart
                        className={`w-5 h-5 ${isFav ? 'fill-[#BE092B] text-[#BE092B]' : 'text-gray-500 group-hover:text-gray-700'}`}
                    />
                </button>
            )}
        </div>
    );

    return (
        <div
            className="bg-white rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-all duration-300 border border-gray-100 cursor-pointer group"
            onClick={() => onClick(property.id)}
        >
            {PropertyImage}
            {PropertyInfo}
        </div>
    );
}, (prevProps, nextProps) => {
    return (
        prevProps.property.id === nextProps.property.id &&
        prevProps.language === nextProps.language &&
        prevProps.property.price === nextProps.property.price &&
        prevProps.property.address === nextProps.property.address &&
        prevProps.index === nextProps.index
    );
});

PropertyCard.displayName = 'PropertyCard';

// Add back the property type utility functions
const getPropertyTypeIcon = (type) => {
    // Ensure we have a string and convert to lowercase
    const propertyType = (type || '').toString().toLowerCase().trim();

    switch (propertyType) {
        case 'apartment':
        case 'شقة':
            return <BiBuildingHouse className="w-4 h-4" />;
        case 'villa':
        case 'فيلا':
            return <IoHomeOutline className="w-4 h-4" />;
        case 'office':
        case 'مكتب':
            return <IoBusinessOutline className="w-4 h-4" />;
        case 'shop':
        case 'محل':
            return <IoStorefrontOutline className="w-4 h-4" />;
        case 'land':
        case 'أرض':
            return <IoEarthOutline className="w-4 h-4" />;
        default:
            return <IoHomeOutline className="w-4 h-4" />;
    }
};

const getPropertyTypeLabel = (type, t) => {
    if (!type) return '';

    // Convert type to lowercase for consistent matching
    const normalizedType = type.toString().toLowerCase().trim();

    // Map Arabic types to English
    const typeMapping = {
        'شقة': 'apartment',
        'فيلا': 'villa',
        'مكتب': 'office',
        'محل': 'shop',
        'أرض': 'land'
    };

    // Get the normalized English type
    const englishType = typeMapping[normalizedType] || normalizedType;

    // Return the appropriate translation based on language
    return t.propertyTypes[englishType] || type;
};

export default function AvailableProperties({ language }) {
    const navigate = useNavigate();
    const [properties, setProperties] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filters, setFilters] = useState({});
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [showLoginPrompt, setShowLoginPrompt] = useState(false);
    const loadingRef = useRef(false);
    const initialLoadDone = useRef(false);

    // Fetch properties without filters
    const fetchProperties = useCallback(async () => {
        if (loadingRef.current) return;

        try {
            loadingRef.current = true;
            setLoading(true);

            const params = new URLSearchParams({
                page: page.toString(),
                per_page: initialLoadDone.current ? '9' : '6',
                language,
                status: 'available'
            });

            const response = await propertyAPI.getProperties(params);

            if (!response?.success || !response?.data) {
                setHasMore(false);
                setProperties([]);
                return;
            }

            const { items, total, currentPage, lastPage, perPage } = response.data;

            if (!items || !Array.isArray(items)) {
                setHasMore(false);
                setProperties([]);
                return;
            }

            setProperties(prev => {
                const newProperties = page === 1 ? items : [...prev, ...items];
                return newProperties;
            });

            setHasMore(currentPage < lastPage);
            if (currentPage < lastPage) {
                setPage(prev => prev + 1);
            }
            initialLoadDone.current = true;
            setError(null);
        } catch (error) {
            console.error('Error fetching properties:', error);
            if (!error.__CACHE_HIT__ && properties.length === 0) {
                setError(
                    language === 'ar'
                        ? 'عذراً، حدث خطأ أثناء تحميل العقارات. يرجى المحاولة مرة أخرى.'
                        : 'Sorry, there was an error loading properties. Please try again.'
                );
                setHasMore(false);
            }
        } finally {
            loadingRef.current = false;
            setLoading(false);
        }
    }, [page, language, properties.length]);

    // Apply client-side filtering
    const filteredProperties = useMemo(() => {
        let filtered = [...properties];

        if (filters.type) {
            filtered = filtered.filter(property => {
                const propertyType = property.type?.toString().toLowerCase().trim();
                const filterType = filters.type.toString().toLowerCase().trim();

                // Map of equivalent types
                const typeEquivalents = {
                    'شقة': 'apartment',
                    'فيلا': 'villa',
                    'مكتب': 'office',
                    'محل': 'shop',
                    'أرض': 'land',
                    'apartment': 'apartment',
                    'villa': 'villa',
                    'office': 'office',
                    'shop': 'shop',
                    'land': 'land'
                };

                // Get normalized types for comparison
                const normalizedPropertyType = typeEquivalents[propertyType] || propertyType;
                const normalizedFilterType = typeEquivalents[filterType] || filterType;

                return normalizedPropertyType === normalizedFilterType;
            });
        }

        if (filters.city) {
            filtered = filtered.filter(property => {
                // Get the formatted address which already handles different formats
                const formattedAddress = formatAddress(property.address || '');
                const propertyAddress = (property.address || '').toLowerCase().trim();
                const formattedAddressLower = formattedAddress.toLowerCase().trim();
                const filterCity = filters.city.toLowerCase().trim();

                // Check multiple variations of the address
                return (
                    formattedAddressLower.includes(filterCity) ||
                    propertyAddress.includes(filterCity) ||
                    // Handle Arabic/English variations of Riyadh
                    (filterCity === 'riyadh' && (
                        formattedAddressLower.includes('riyadh') ||
                        formattedAddressLower.includes('الرياض')
                    )) ||
                    (filterCity === 'الرياض' && (
                        formattedAddressLower.includes('riyadh') ||
                        formattedAddressLower.includes('الرياض')
                    ))
                );
            });
        }

        if (filters.price_min) {
            filtered = filtered.filter(property =>
                parseFloat(property.price) >= parseFloat(filters.price_min)
            );
        }

        if (filters.price_max) {
            filtered = filtered.filter(property =>
                parseFloat(property.price) <= parseFloat(filters.price_max)
            );
        }

        if (filters.number_bedroom) {
            filtered = filtered.filter(property =>
                property.number_bedroom === parseInt(filters.number_bedroom)
            );
        }

        if (filters.number_bathroom) {
            filtered = filtered.filter(property =>
                property.number_bathroom === parseInt(filters.number_bathroom)
            );
        }

        if (filters.area_min) {
            filtered = filtered.filter(property =>
                property.area >= parseFloat(filters.area_min)
            );
        }

        if (filters.area_max) {
            filtered = filtered.filter(property =>
                property.area <= parseFloat(filters.area_max)
            );
        }

        return filtered;
    }, [properties, filters]);

    // Initial fetch
    useEffect(() => {
        fetchProperties();
    }, [fetchProperties]);

    const handleFilterChange = useCallback((newFilters) => {
        setFilters(newFilters);
    }, []);

    // Add infinite scroll
    useEffect(() => {
        const handleScroll = () => {
            if (
                window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 1000 &&
                !loading &&
                hasMore &&
                // Only fetch more if we're not filtering
                Object.keys(filters).length === 0
            ) {
                fetchProperties();
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [fetchProperties, loading, hasMore, filters]);

    const t = content[language];

    return (
        <div className="container mx-auto px-4 py-8">
            {showLoginPrompt && (
                <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
                    <div className="bg-white rounded-xl p-6 max-w-sm w-full shadow-xl">
                        <h3 className="text-xl font-semibold mb-3">
                            {language === 'ar' ? 'تسجيل الدخول مطلوب' : 'Login Required'}
                        </h3>
                        <p className="text-gray-600 mb-6">
                            {language === 'ar'
                                ? 'يجب تسجيل الدخول لإضافة العقارات إلى المفضلة'
                                : 'Please login to add properties to your favorites'}
                        </p>
                        <div className="flex gap-3">
                            <button
                                onClick={() => {
                                    setShowLoginPrompt(false);
                                    navigate('/login/renter');
                                }}
                                className="flex-1 bg-[#BE092B] text-white py-2.5 rounded-lg font-medium hover:bg-[#9C0722] transition-colors"
                            >
                                {language === 'ar' ? 'تسجيل الدخول' : 'Login'}
                            </button>
                            <button
                                onClick={() => setShowLoginPrompt(false)}
                                className="flex-1 bg-gray-100 text-gray-700 py-2.5 rounded-lg font-medium hover:bg-gray-200 transition-colors"
                            >
                                {language === 'ar' ? 'إلغاء' : 'Cancel'}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 mb-6">
                <h1 className={`text-2xl font-bold ${language === 'ar' ? 'font-arabic' : ''}`}>
                    {t.title}
                </h1>
                <PropertyFilters
                    language={language}
                    onFilterChange={handleFilterChange}
                />
            </div>

            {error && (
                <div className="bg-red-50 border border-red-200 rounded-lg p-4 mb-6">
                    <div className="flex justify-between items-center">
                        <p className="text-red-600">{error}</p>
                        <button
                            onClick={() => {
                                setError(null);
                                setPage(1);
                                setProperties([]);
                                setHasMore(true);
                                fetchProperties();
                            }}
                            className="text-primary hover:text-primary-dark underline ml-4"
                        >
                            {language === 'ar' ? 'إعادة المحاولة' : 'Try Again'}
                        </button>
                    </div>
                </div>
            )}

            {/* Property Grid */}
            {loading && properties.length === 0 ? (
                <div className="animate-pulse space-y-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {[1, 2, 3].map(i => (
                            <div key={i} className="h-64 bg-gray-200 rounded"></div>
                        ))}
                    </div>
                </div>
            ) : !loading && filteredProperties.length === 0 ? (
                <div className="text-center py-12 bg-gray-50 rounded-lg">
                    {Object.keys(filters).length > 0 ? (
                        <>
                            <p className="text-gray-500 mb-2">
                                {language === 'ar' ? 'لا توجد عقارات تطابق معايير البحث' : 'No properties found matching your criteria'}
                            </p>
                            <p className="text-gray-400">
                                {language === 'ar' ? 'جرب تعديل الفلاتر أو' : 'Try adjusting your filters or'}{' '}
                                <button
                                    onClick={() => handleFilterChange({})}
                                    className="text-primary hover:text-primary-dark underline focus:outline-none"
                                >
                                    {language === 'ar' ? 'إعادة تعيين الكل' : 'reset all filters'}
                                </button>
                            </p>
                        </>
                    ) : (
                        <p className="text-gray-500">
                            {language === 'ar' ? 'لا توجد عقارات متاحة' : 'No properties available'}
                        </p>
                    )}
                </div>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {filteredProperties.map((property, index) => (
                        <PropertyCard
                            key={property.id}
                            property={property}
                            language={language}
                            onClick={() => navigate(`/properties/${property.id}`)}
                            onLoginRequired={() => setShowLoginPrompt(true)}
                            index={index}
                        />
                    ))}
                </div>
            )}

            {loading && properties.length > 0 && (
                <div className="mt-6 text-center">
                    <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-[#BE092B] border-r-transparent"></div>
                </div>
            )}
        </div>
    );
} 