import { useState, useEffect, useRef } from 'react';
import { FiFilter, FiChevronDown } from 'react-icons/fi';

// Comprehensive list of Saudi Arabian cities with their common variations and coordinates
const MAIN_CITIES = [
    {
        id: 'riyadh',
        label: { en: 'Riyadh', ar: 'الرياض' },
        variations: ['Riyadh', 'الرياض', 'Al Riyadh', 'Er Riyadh'],
        coordinates: { lat: 24.7136, lng: 46.6753 }
    },
    {
        id: 'jeddah',
        label: { en: 'Jeddah', ar: 'جدة' },
        variations: ['Jeddah', 'جدة', 'Jiddah', 'Jaddah'],
        coordinates: { lat: 21.5433, lng: 39.1728 }
    },
    {
        id: 'dammam',
        label: { en: 'Dammam', ar: 'الدمام' },
        variations: ['Dammam', 'الدمام', 'Ad Dammam'],
        coordinates: { lat: 26.4207, lng: 50.0888 }
    },
];

const OTHER_CITIES = [
    { id: 'mecca', label: { en: 'Mecca', ar: 'مكة المكرمة' } },
    { id: 'medina', label: { en: 'Medina', ar: 'المدينة المنورة' } },
    { id: 'khobar', label: { en: 'Khobar', ar: 'الخبر' } },
    { id: 'dhahran', label: { en: 'Dhahran', ar: 'الظهران' } },
    { id: 'qatif', label: { en: 'Qatif', ar: 'القطيف' } },
    { id: 'jubail', label: { en: 'Jubail', ar: 'الجبيل' } },
    { id: 'hofuf', label: { en: 'Hofuf', ar: 'الهفوف' } },
    { id: 'mubarraz', label: { en: 'Mubarraz', ar: 'المبرز' } },
    { id: 'buraydah', label: { en: 'Buraydah', ar: 'بريدة' } },
    { id: 'unaizah', label: { en: 'Unaizah', ar: 'عنيزة' } },
    { id: 'hail', label: { en: 'Hail', ar: 'حائل' } },
    { id: 'majmaah', label: { en: 'Majmaah', ar: 'المجمعة' } },
    { id: 'kharj', label: { en: 'Al-Kharj', ar: 'الخرج' } },
    { id: 'taif', label: { en: 'Taif', ar: 'الطائف' } },
    { id: 'yanbu', label: { en: 'Yanbu', ar: 'ينبع' } },
    { id: 'rabigh', label: { en: 'Rabigh', ar: 'رابغ' } },
    { id: 'tabuk', label: { en: 'Tabuk', ar: 'تبوك' } },
    { id: 'arar', label: { en: 'Arar', ar: 'عرعر' } },
    { id: 'sakaka', label: { en: 'Sakaka', ar: 'سكاكا' } },
    { id: 'rafha', label: { en: 'Rafha', ar: 'رفحاء' } },
    { id: 'abha', label: { en: 'Abha', ar: 'أبها' } },
    { id: 'khamis-mushait', label: { en: 'Khamis Mushait', ar: 'خميس مشيط' } },
    { id: 'najran', label: { en: 'Najran', ar: 'نجران' } },
    { id: 'jizan', label: { en: 'Jizan', ar: 'جازان' } },
    { id: 'al-bahah', label: { en: 'Al Bahah', ar: 'الباحة' } },
    { id: 'al-qunfudhah', label: { en: 'Al Qunfudhah', ar: 'القنفذة' } },
    { id: 'al-rass', label: { en: 'Al Rass', ar: 'الرس' } },
    { id: 'wadi-ad-dawasir', label: { en: 'Wadi Ad-Dawasir', ar: 'وادي الدواسر' } },
    { id: 'dawadmi', label: { en: 'Dawadmi', ar: 'الدوادمي' } },
    { id: 'zulfi', label: { en: 'Zulfi', ar: 'الزلفي' } },
    { id: 'afif', label: { en: 'Afif', ar: 'عفيف' } },
    { id: 'al-qurayyat', label: { en: 'Al-Qurayyat', ar: 'القريات' } },
    { id: 'al-khafji', label: { en: 'Al-Khafji', ar: 'الخفجي' } },
    { id: 'hafar-al-batin', label: { en: 'Hafar Al-Batin', ar: 'حفر الباطن' } },
    { id: 'al-jubail', label: { en: 'Al Jubail', ar: 'الجبيل' } },
    { id: 'al-khubar', label: { en: 'Al Khubar', ar: 'الخبر' } },
    { id: 'al-qatif', label: { en: 'Al Qatif', ar: 'القطيف' } },
    { id: 'al-ahsa', label: { en: 'Al-Ahsa', ar: 'الأحساء' } }
].sort((a, b) => a.label.en.localeCompare(b.label.en));

const CITIES = [...MAIN_CITIES, ...OTHER_CITIES];

export default function PropertyFilters({ language, onFilterChange }) {
    const [isOpen, setIsOpen] = useState(false);
    const [localFilters, setLocalFilters] = useState({
        type: '',
        price_min: '',
        price_max: '',
        area_min: '',
        area_max: '',
        number_bedroom: '',
        number_bathroom: '',
        city: ''
    });
    const filterRef = useRef(null);

    const handleFilterChange = (name, value) => {
        // Create new filters object
        const newFilters = {
            ...localFilters,
            [name]: value
        };
        setLocalFilters(newFilters);

        // Prepare filters for frontend filtering
        const frontendFilters = {};
        Object.entries(newFilters).forEach(([key, val]) => {
            if (val === '') return; // Skip empty values

            if (['price_min', 'price_max', 'area_min', 'area_max'].includes(key)) {
                frontendFilters[key] = parseFloat(val) || 0;
            } else if (['number_bedroom', 'number_bathroom'].includes(key)) {
                frontendFilters[key] = parseInt(val) || 0;
            } else if (key === 'type') {
                // For frontend filtering, ensure we match the property type exactly
                frontendFilters[key] = val.toLowerCase();
            } else {
                frontendFilters[key] = val;
            }
        });

        // Log the filters being sent (for debugging)
        console.log('Sending filters:', frontendFilters);

        // Send filters to parent
        onFilterChange(frontendFilters);
    };

    // Helper function to normalize city names
    const normalizeCityName = (address) => {
        if (!address) return '';

        // Convert to lowercase and remove diacritics
        const normalized = address.toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");

        // Try to match with city variations
        for (const city of CITIES) {
            const variations = city.variations || [city.label.en, city.label.ar];
            const found = variations.some(variation =>
                normalized.includes(variation.toLowerCase())
            );
            if (found) return city.id;
        }

        return '';
    };

    // Handle click outside to close filter
    useEffect(() => {
        function handleClickOutside(event) {
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const resetFilters = () => {
        console.log('Resetting filters'); // Debug log
        const emptyFilters = {
            type: '',
            price_min: '',
            price_max: '',
            area_min: '',
            area_max: '',
            number_bedroom: '',
            number_bathroom: '',
            city: ''
        };
        setLocalFilters(emptyFilters);
        onFilterChange({});
    };

    // Count active filters
    const activeFiltersCount = Object.values(localFilters).filter(value => value !== '').length;

    return (
        <div className="relative w-full md:w-72" ref={filterRef}>
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="w-full px-4 py-3 flex items-center justify-between text-sm font-medium text-gray-700 hover:bg-gray-50 transition-colors bg-white rounded-lg shadow-sm border border-gray-200"
            >
                <div className="flex items-center gap-2">
                    <FiFilter className="text-gray-400 w-4 h-4" />
                    <span>
                        {language === 'ar' ? 'تصفية' : 'Filters'}
                        {activeFiltersCount > 0 && ` (${activeFiltersCount})`}
                    </span>
                </div>
                <FiChevronDown
                    className={`transition-transform duration-200 ${isOpen ? 'rotate-180' : ''} w-4 h-4`}
                />
            </button>

            {isOpen && (
                <div className="absolute top-full left-0 right-0 mt-2 bg-white rounded-lg shadow-lg z-50 border border-gray-200 min-w-[300px]">
                    <div className="p-4 space-y-4">
                        <div className="grid grid-cols-1 gap-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    {language === 'ar' ? 'نوع العقار' : 'Property Type'}
                                </label>
                                <select
                                    value={localFilters.type}
                                    onChange={(e) => handleFilterChange('type', e.target.value)}
                                    className="w-full rounded-md border border-gray-200 py-2.5 px-3 outline-none focus:border-[#BE092B] appearance-none text-sm"
                                >
                                    <option value="">{language === 'ar' ? 'الكل' : 'All'}</option>
                                    {language === 'ar' ? (
                                        <>
                                            <option value="apartment">شقة</option>
                                            <option value="villa">فيلا</option>
                                            <option value="office">مكتب</option>
                                            <option value="shop">محل</option>
                                            <option value="land">أرض</option>
                                        </>
                                    ) : (
                                        <>
                                            <option value="apartment">Apartment</option>
                                            <option value="villa">Villa</option>
                                            <option value="office">Office</option>
                                            <option value="shop">Shop</option>
                                            <option value="land">Land</option>
                                        </>
                                    )}
                                </select>
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    {language === 'ar' ? 'المدينة' : 'City'}
                                </label>
                                <select
                                    value={localFilters.city}
                                    onChange={(e) => handleFilterChange('city', e.target.value)}
                                    className="w-full rounded-md border border-gray-200 py-2.5 px-3 outline-none focus:border-[#BE092B] appearance-none text-sm"
                                >
                                    <option value="">{language === 'ar' ? 'كل المدن' : 'All Cities'}</option>
                                    {CITIES.map(city => (
                                        <option key={city.id} value={city.id}>
                                            {city.label[language === 'ar' ? 'ar' : 'en']}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                {language === 'ar' ? 'السعر' : 'Price'}
                            </label>
                            <div className="grid grid-cols-2 gap-3">
                                <input
                                    type="number"
                                    min="0"
                                    step="1000"
                                    placeholder={language === 'ar' ? 'من' : 'Min'}
                                    value={localFilters.price_min}
                                    onChange={(e) => handleFilterChange('price_min', e.target.value)}
                                    className="w-full bg-white border border-gray-200 px-3 py-2.5 text-sm rounded-md focus:outline-none focus:ring-1 focus:ring-[#BE092B] focus:border-[#BE092B] transition-colors [&:not(:placeholder-shown)]:bg-[#BE092B]/5 hover:border-[#BE092B]/30"
                                />
                                <input
                                    type="number"
                                    min="0"
                                    step="1000"
                                    placeholder={language === 'ar' ? 'إلى' : 'Max'}
                                    value={localFilters.price_max}
                                    onChange={(e) => handleFilterChange('price_max', e.target.value)}
                                    className="w-full bg-white border border-gray-200 px-3 py-2.5 text-sm rounded-md focus:outline-none focus:ring-1 focus:ring-[#BE092B] focus:border-[#BE092B] transition-colors [&:not(:placeholder-shown)]:bg-[#BE092B]/5 hover:border-[#BE092B]/30"
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-2 gap-3">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    {language === 'ar' ? 'غرف النوم' : 'Bedrooms'}
                                </label>
                                <select
                                    value={localFilters.number_bedroom}
                                    onChange={(e) => handleFilterChange('number_bedroom', e.target.value)}
                                    className="w-full rounded-md border border-gray-200 py-2.5 px-3 outline-none focus:border-[#BE092B] appearance-none text-sm"
                                >
                                    <option value="">{language === 'ar' ? 'الكل' : 'All'}</option>
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(num => (
                                        <option key={num} value={num}>{num}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    {language === 'ar' ? 'الحمامات' : 'Bathrooms'}
                                </label>
                                <select
                                    value={localFilters.number_bathroom}
                                    onChange={(e) => handleFilterChange('number_bathroom', e.target.value)}
                                    className="w-full rounded-md border border-gray-200 py-2.5 px-3 outline-none focus:border-[#BE092B] appearance-none text-sm"
                                >
                                    <option value="">{language === 'ar' ? 'الكل' : 'All'}</option>
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(num => (
                                        <option key={num} value={num}>{num}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                {language === 'ar' ? 'المساحة' : 'Area'}
                            </label>
                            <div className="grid grid-cols-2 gap-3">
                                <input
                                    type="number"
                                    min="0"
                                    placeholder={language === 'ar' ? 'من' : 'Min'}
                                    value={localFilters.area_min}
                                    onChange={(e) => handleFilterChange('area_min', e.target.value)}
                                    className="w-full bg-white border border-gray-200 px-3 py-2.5 text-sm rounded-md focus:outline-none focus:ring-1 focus:ring-[#BE092B] focus:border-[#BE092B] transition-colors [&:not(:placeholder-shown)]:bg-[#BE092B]/5 hover:border-[#BE092B]/30"
                                />
                                <input
                                    type="number"
                                    min="0"
                                    placeholder={language === 'ar' ? 'إلى' : 'Max'}
                                    value={localFilters.area_max}
                                    onChange={(e) => handleFilterChange('area_max', e.target.value)}
                                    className="w-full bg-white border border-gray-200 px-3 py-2.5 text-sm rounded-md focus:outline-none focus:ring-1 focus:ring-[#BE092B] focus:border-[#BE092B] transition-colors [&:not(:placeholder-shown)]:bg-[#BE092B]/5 hover:border-[#BE092B]/30"
                                />
                            </div>
                        </div>

                        <div className="flex justify-between gap-2 pt-2 border-t border-gray-100">
                            <button
                                onClick={resetFilters}
                                className="px-4 py-2 text-sm text-gray-600 hover:text-gray-800 transition-colors hover:bg-gray-50 rounded-md"
                            >
                                {language === 'ar' ? 'إعادة تعيين' : 'Reset'}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
