/**
 * Formats a phone number to the standard E.164 format for Saudi numbers
 * Accepts formats:
 * - 0550427014
 * - 550427014
 * - +966550427014
 * - 966550427014
 * Returns: +966XXXXXXXXX (with + prefix) or null if invalid
 */
export const formatPhoneNumber = (phone) => {
  try {
    // Remove all non-digit characters except +
    let cleaned = phone.replace(/[^\d+]/g, '')
    
    // If number already starts with +966, validate and return
    if (cleaned.startsWith('+966')) {
      return /^\+966\d{9}$/.test(cleaned) ? cleaned : null;
    }
    
    // Remove leading zeros
    cleaned = cleaned.replace(/^0+/, '')
    
    // Remove 966 prefix if present
    if (cleaned.startsWith('966')) {
      cleaned = cleaned.substring(3)
    }
    
    // Check if remaining number is valid (should be 9 digits starting with 5)
    if (!/^5\d{8}$/.test(cleaned)) {
      return null;
    }
    
    // Add +966 prefix
    return `+966${cleaned}`;
  } catch (error) {
    return null;
  }
}

/**
 * Validates if a phone number is a valid Saudi mobile number
 * Accepts all common formats
 */
export const validateSaudiPhone = (phone) => {
  const formattedPhone = formatPhoneNumber(phone);
  return formattedPhone !== null;
}

/**
 * Formats a phone number for display in the UI
 * Input: any valid format
 * Output: +966 5X XXX XXXX
 */
export const formatPhoneForDisplay = (phone) => {
  try {
    // First normalize the number
    const normalized = formatPhoneNumber(phone)
    const cleaned = normalized.replace(/^\+966/, '')
    
    // Format as: +966 5X XXX XXXX
    return `+966 ${cleaned.slice(0, 1)} ${cleaned.slice(1, 4)} ${cleaned.slice(4)}`
  } catch (error) {
    return phone // Return original if formatting fails
  }
}

/**
 * Normalizes any valid Saudi phone format to API format
 * Input: any valid format
 * Output: +966XXXXXXXXX (with + prefix)
 */
export const normalizePhone = (phone) => {
  return formatPhoneNumber(phone)
}

/**
 * Validates if a string is a valid OTP code
 */
export const validateOTP = (otp) => {
  return /^\d{6}$/.test(otp)
}
