import axios from 'axios'
import { toast } from 'react-hot-toast'

// Base URL configuration - Use direct Azure URL to avoid Firebase rewrites
const isProduction = import.meta.env.PROD;
// Use direct Azure URL in production
const API_URL = isProduction 
  ? 'https://stark-aahbczewh4f0hjca.uaenorth-01.azurewebsites.net' // Direct Azure URL
  : `https://${import.meta.env.VITE_API_URL}`;

// Create axios instance with optimized settings
const axiosInstance = axios.create({
  baseURL: `${API_URL}/api/v1`,
  timeout: 120000, // Increased to 2 minutes for file uploads
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: false, // Set to false since we're using token-based auth
})

// Add caching mechanism for specific endpoints only
const cache = new Map();
const CACHE_DURATION = 30 * 60 * 1000; // 30 minutes
const STALE_WHILE_REVALIDATE = 24 * 60 * 60 * 1000; // 24 hours
const CACHEABLE_ENDPOINTS = ['/units', '/units/type', '/categories', '/setting', '/FAQ'];

// Preload cache from localStorage on module load
try {
  CACHEABLE_ENDPOINTS.forEach(endpoint => {
    const key = `${endpoint}`;
    const cached = localStorage.getItem(key);
    if (cached) {
      try {
        const { data, timestamp } = JSON.parse(cached);
        if (Date.now() - timestamp < STALE_WHILE_REVALIDATE) {
          cache.set(key, { data, timestamp });
        } else {
          localStorage.removeItem(key);
        }
      } catch (e) {
        localStorage.removeItem(key);
      }
    }
  });
} catch (e) {
  console.warn('Cache preload error:', e);
}

// Request interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token')
    const language = localStorage.getItem('language') || 'en'

    // Set language headers
    config.headers['Accept-Language'] = language
    config.headers['lang'] = language
    config.headers['Content-Language'] = language
    
    // Set authorization header if token exists
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    // For file uploads, adjust content type and timeout
    if (config.data instanceof FormData) {
      config.timeout = 300000; // 5 minutes for file uploads
      delete config.headers['Content-Type']; // Let browser set correct boundary
    }

    return config;
  },
  error => {
    console.warn('Request interceptor error:', error);
    return Promise.reject(error);
  }
)

// Response interceptor
axiosInstance.interceptors.response.use(
  response => {
    // Cache successful GET responses for cacheable endpoints
    if (response.config.method?.toLowerCase() === 'get') {
      const endpoint = CACHEABLE_ENDPOINTS.find(e => response.config.url?.startsWith(e));
      if (endpoint) {
        const cacheKey = `${response.config.url}${JSON.stringify(response.config.params || {})}`;
        const cacheData = {
          data: response.data,
          timestamp: Date.now()
        };
        
        // Update memory cache
        cache.set(cacheKey, cacheData);
        
        // Update localStorage cache
        try {
          localStorage.setItem(cacheKey, JSON.stringify(cacheData));
        } catch (e) {
          console.warn('localStorage cache update failed:', e);
        }
      }
    }
    return response;
  },
  error => {
    // Handle CORS errors specifically
    if (error.message?.includes('Network Error') || error.code === 'ERR_NETWORK') {
      console.error('CORS/Network Error:', {
        url: error.config?.url,
        method: error.config?.method,
        status: error.response?.status,
        message: error.message,
        headers: error.config?.headers
      });

      // Try to get cached data for GET requests
      if (error.config?.method?.toLowerCase() === 'get') {
        const endpoint = CACHEABLE_ENDPOINTS.find(e => error.config.url?.startsWith(e));
        if (endpoint) {
          const cacheKey = `${error.config.url}${JSON.stringify(error.config.params || {})}`;
          const cachedData = cache.get(cacheKey) || JSON.parse(localStorage.getItem(cacheKey) || 'null');
          
          if (cachedData && (Date.now() - cachedData.timestamp < STALE_WHILE_REVALIDATE)) {
            console.log('Returning cached data due to CORS error');
            return Promise.resolve({ data: cachedData.data });
          }
        }
      }
      
      return Promise.reject({
        error: [{
          field: 'submit',
          messages: ['Network error. Please try again later.']
        }]
      });
    }

    // Handle timeout errors
    if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
      return Promise.reject({
        error: [{
          field: 'submit',
          messages: ['Request timed out. Try with smaller or fewer images.']
        }]
      });
    }

    // Handle 401 Unauthorized
    if (error.response?.status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      window.location.href = '/login';
      return Promise.reject(error);
    }

    // Handle other errors
    return Promise.reject(error.response?.data || {
      error: [{
        field: 'submit',
        messages: [error.message || 'An error occurred. Please try again.']
      }]
    });
  }
)

export default axiosInstance