const IMAGE_CDN_BASE = 'https://storage.googleapis.com';

export const optimizeImage = (url, options = {}) => {
  if (!url || url.includes('placehold.co')) return url;

  try {
    // Default options
    const {
      width = 400,
      quality = 80,
      format = 'webp',
      blur = 0,
    } = options;

    // If it's already a CDN URL, modify its parameters
    if (url.startsWith(IMAGE_CDN_BASE)) {
      const urlObj = new URL(url);
      urlObj.searchParams.set('w', width);
      urlObj.searchParams.set('q', quality);
      urlObj.searchParams.set('f', format);
      if (blur > 0) urlObj.searchParams.set('b', blur);
      return urlObj.toString();
    }

    // Return original URL if not a Google Storage URL
    return url;
  } catch (error) {
    console.error('Image optimization error:', error);
    return url;
  }
};

export const generateBlurHash = (url) => {
  return optimizeImage(url, {
    width: 32,
    quality: 30,
    blur: 10,
  });
};

export const imageKitLoader = ({ src, width, quality = 75 }) => {
  return optimizeImage(src, {
    width,
    quality,
    format: 'webp'
  });
};

export const compressImage = async (file, options = {}) => {
  const {
    maxWidth = 1920,
    maxHeight = 1080,
    quality = 0.8,
    format = 'webp'
  } = options;

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;

        // Calculate new dimensions while maintaining aspect ratio
        if (width > maxWidth) {
          height = Math.round((height * maxWidth) / width);
          width = maxWidth;
        }
        if (height > maxHeight) {
          width = Math.round((width * maxHeight) / height);
          height = maxHeight;
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            if (!blob) {
              reject(new Error('Canvas to Blob conversion failed'));
              return;
            }
            const optimizedFile = new File(
              [blob],
              `${file.name.split('.')[0]}.${format}`,
              {
                type: `image/${format}`,
                lastModified: Date.now(),
              }
            );
            resolve({
              file: optimizedFile,
              dimensions: { width, height },
              size: optimizedFile.size,
              originalSize: file.size,
            });
          },
          `image/${format}`,
          quality
        );
      };
      img.onerror = reject;
    };
    reader.onerror = reject;
  });
};

export const processImages = async (files, onProgress) => {
  const optimizedImages = [];
  const totalFiles = files.length;

  for (let i = 0; i < totalFiles; i++) {
    const file = files[i];
    
    // Skip non-image files
    if (!file.type.startsWith('image/')) {
      continue;
    }

    try {
      const optimized = await compressImage(file, {
        maxWidth: 1920,
        maxHeight: 1080,
        quality: 0.8,
        format: 'webp'
      });

      optimizedImages.push(optimized);

      // Report progress
      if (onProgress) {
        onProgress((i + 1) / totalFiles * 100);
      }
    } catch (error) {
      console.error(`Error processing image ${file.name}:`, error);
    }
  }

  return optimizedImages;
}; 