import { useState, useEffect, useMemo, memo, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { propertyAPI } from '../../services/api'
import { useFavorites } from '../../hooks/useFavorites'
import { FiMaximize, FiMapPin, FiHeart, FiArrowLeft, FiX, FiChevronLeft, FiChevronRight, FiZoomIn } from 'react-icons/fi'
import { IoBedOutline, IoWaterOutline } from "react-icons/io5"
import { GoogleMap, MarkerF, useLoadScript } from '@react-google-maps/api'
import { defaultCenter, mapOptions } from '../../config/googleMapsConfig'
import BookingForm from './BookingForm'
import { Loader } from '@googlemaps/js-api-loader'
import {
  FaParking,
  FaSwimmingPool,
  FaDumbbell,
  FaShieldAlt,
  FaBuilding,
  FaTree,
  FaSnowflake,
  FaUmbrella,
  FaUser,
  FaBox,
  FaBlender,
  FaWifi,
  FaSatellite,
  FaPhone,
  FaTools,
  FaMosque,
  FaShoppingCart,
  FaGraduationCap,
  FaPaw,
  FaWater,
  FaCity,
  FaLeaf,
  FaRoad,
  FaStore
} from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import { optimizeImage, generateBlurHash } from '../../utils/imageOptimizer';
import SARPrice from '../shared/SARPrice';
import { useAuth } from '../../contexts/AuthContext';

const content = {
  en: {
    loading: 'Loading property details...',
    error: 'Failed to load property details',
    description: 'Description',
    features: 'Features',
    amenities: 'Amenities',
    location: 'Location',
    bookTour: 'Book a Tour',
    saveToFavorites: 'Save to Favorites',
    removeFromFavorites: 'Remove from Favorites',
    currency: 'SAR',
    sqm: 'm²',
    bedrooms: 'Bedrooms',
    bathrooms: 'Bathrooms',
    area: 'Area',
    propertyTypes: {
      apartment: 'Apartment',
      villa: 'Villa',
      office: 'Office',
      shop: 'Shop',
      land: 'Land',
      شقة: 'Apartment',
      فيلا: 'Villa',
      مكتب: 'Office',
      محل: 'Shop',
      أرض: 'Land'
    },
    categories: {
      'Amenities': 'Amenities',
      'Additional Features': 'Additional Features'
    },
    features_en: {
      1: 'Parking',
      2: 'Swimming Pool',
      3: 'Gym',
      4: '24/7 Security',
      5: 'Elevator',
      6: 'Garden',
      7: 'Central AC',
      8: 'Balcony',
      9: "Maid's Room",
      10: 'Storage Room',
      11: 'Kitchen Appliances',
      12: 'Internet',
      13: 'Satellite/Cable TV',
      14: 'Intercom',
      15: 'Maintenance',
      16: 'Nearby Mosque',
      17: 'Shopping Centers',
      18: 'Schools Nearby',
      19: 'Pets Allowed',
      20: 'Sea View',
      21: 'City View',
      22: 'Garden View',
      23: 'Street View',
      24: 'Mall View'
    },
    propertyLocation: 'Property Location'
  },
  ar: {
    loading: 'جاري تحميل تفاصيل العقار...',
    error: 'فشل في تحميل تفاصي العقار',
    description: 'الوصف',
    features: 'المميزات',
    amenities: 'المرافق',
    location: 'الموقع',
    bookTour: 'حجز جولة',
    saveToFavorites: 'حفظ في المفضلة',
    removeFromFavorites: 'إزالة من المفضلة',
    currency: 'ريال',
    sqm: 'م²',
    bedrooms: 'غرف النوم',
    bathrooms: 'دورات المياه',
    area: 'المساحة',
    propertyTypes: {
      apartment: 'شقة',
      villa: 'فيلا',
      office: 'مكتب',
      shop: 'محل',
      land: 'أرض',
      شقة: 'شقة',
      فيلا: 'فيلا',
      مكتب: 'مكتب',
      محل: 'محل',
      أرض: 'أرض'
    },
    categories: {
      'Amenities': 'المرافق',
      'Additional Features': 'مميزات إضافية'
    },
    features_ar: {
      1: 'موقف سيارات',
      2: 'مسبح',
      3: 'صالة رياضية',
      4: 'أمن 24/7',
      5: 'مصعد',
      6: 'حديقة',
      7: 'تكييف مركزي',
      8: 'شرفة',
      9: 'غرفة خادمة',
      10: 'غرفة تخزين',
      11: 'أجهزة مطبخ',
      12: 'خدمة إنترنت',
      13: 'قنوات فضائية',
      14: 'اتصال داخلي',
      15: 'صيانة',
      16: 'مسجد قريب',
      17: 'مراكز تسوق قريبة',
      18: 'مدارس قريبة',
      19: 'يسمح بالحيوانات',
      20: 'إطلالة بحرية',
      21: 'إطلالة على المدينة',
      22: 'إطلالة على الحديقة',
      23: 'إطلالة على الطريق',
      24: 'إطلالة على المول'
    },
    propertyLocation: 'موقع العقار'
  }
}

// Feature icons mapping
const featureIcons = {
  1: FaParking,      // Parking
  2: FaSwimmingPool, // Swimming Pool
  3: FaDumbbell,     // Gym
  4: FaShieldAlt,    // 24/7 Security
  5: FaBuilding,     // Elevator
  6: FaTree,         // Garden
  7: FaSnowflake,    // Central AC
  8: FaUmbrella,     // Balcony
  9: FaUser,         // Maid's Room
  10: FaBox,         // Storage Room
  11: FaBlender,     // Kitchen Appliances
  12: FaWifi,        // Internet
  13: FaSatellite,   // Satellite/Cable TV
  14: FaPhone,       // Intercom
  15: FaTools,       // Maintenance
  16: FaMosque,      // Nearby Mosque
  17: FaShoppingCart, // Shopping Centers
  18: FaGraduationCap, // Schools Nearby
  19: FaPaw,         // Pets Allowed
  20: FaWater,       // Sea View
  21: FaCity,        // City View
  22: FaLeaf,        // Garden View
  23: FaRoad,        // Street View
  24: FaStore        // Mall View
};

// Add formatAddress helper function at the top level
const formatAddress = (address, language) => {
  if (!address) return '';

  // Check if the address is in Arabic or English
  const isArabic = /[\u0600-\u06FF]/.test(address);

  // Split by appropriate delimiter
  const parts = isArabic ? address.split('،') : address.split(',');

  // Clean and filter parts
  const cleanParts = parts.map(part => part.trim()).filter(Boolean);

  // Function to identify if a part is likely a city or neighborhood
  const isLocationPart = (part) => {
    // Skip parts that are:
    if (
      // Plus codes
      /^[A-Z0-9]{4,}\+[A-Z0-9]{3,}/.test(part) ||
      // Postal codes
      /^\d{5,}$/.test(part) ||
      // Street numbers or building codes
      /^[A-Z0-9]+$/.test(part) ||
      // Country names
      /saudi arabia|المملكة العربية السعودية|السعودية|ksa/i.test(part)
    ) {
      return false;
    }
    return true;
  };

  // Filter to keep only location parts and clean them
  const locationParts = cleanParts
    .filter(isLocationPart)
    .map(part => {
      // Remove postal codes and street numbers
      part = part.replace(/\s*\d{4,}.*$/, '');
      part = part.replace(/^RHHA\d+،?\s*/, '');

      // For Arabic addresses, ensure حي is only included if it's part of the actual name
      if (isArabic) {
        if (part.startsWith('حي ')) {
          const withoutPrefix = part.replace(/^حي\s+/, '');
          return withoutPrefix;
        }
      }

      // For English addresses, clean up district/area prefixes
      if (!isArabic) {
        part = part.replace(/^(district|area|neighborhood)\s*/i, '');
      }

      return part.trim();
    })
    .filter(Boolean);

  // Take the last two significant parts (usually neighborhood and city)
  const significantParts = locationParts.length > 2
    ? locationParts.slice(-2)
    : locationParts;

  // Join with the appropriate delimiter based on the original language
  return significantParts.join(isArabic ? '، ' : ', ');
};

// Add helper function for property type translation
const getPropertyType = (type, language, t) => {
  if (!type) return '';

  // Convert to lowercase for consistent matching
  const normalizedType = type.toLowerCase().trim();

  // Get translation from content object
  return language === 'en'
    ? t.propertyTypes[normalizedType] || type
    : t.propertyTypes[normalizedType] || type;
};

// Add OptimizedImage component
const OptimizedImage = memo(({ src, alt, className, priority = false, onLoad }) => {
  const [isLoading, setIsLoading] = useState(true);
  const blurUrl = useMemo(() => generateBlurHash(src), [src]);

  return (
    <div className={`relative ${className || ''}`}>
      {isLoading && (
        <img
          src={blurUrl}
          alt={alt}
          className="absolute inset-0 w-full h-full object-cover blur-sm"
          aria-hidden="true"
        />
      )}
      <img
        src={optimizeImage(src, {
          width: priority ? 1920 : 800,
          quality: priority ? 90 : 80,
          format: 'webp'
        })}
        alt={alt}
        className={`${className || ''} ${isLoading ? 'opacity-0' : 'opacity-100'} transition-opacity duration-300`}
        loading={priority ? 'eager' : 'lazy'}
        decoding={priority ? 'sync' : 'async'}
        onLoad={(e) => {
          setIsLoading(false);
          onLoad?.(e);
        }}
      />
    </div>
  );
});

OptimizedImage.displayName = 'OptimizedImage';

// Add ImageLightbox component
const ImageLightbox = ({ images, currentIndex, onClose, onNavigate }) => {
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // Handle keyboard navigation
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') onClose();
      if (e.key === 'ArrowRight') onNavigate('next');
      if (e.key === 'ArrowLeft') onNavigate('prev');
    };

    window.addEventListener('keydown', handleKeyDown);
    // Prevent scrolling while lightbox is open
    document.body.style.overflow = 'hidden';

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      document.body.style.overflow = 'auto';
    };
  }, [onClose, onNavigate]);

  // Touch handlers for swipe
  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;

    if (isLeftSwipe) {
      onNavigate('next');
    }
    if (isRightSwipe) {
      onNavigate('prev');
    }

    setTouchStart(null);
    setTouchEnd(null);
  };

  return (
    <div
      className="fixed inset-0 z-50 bg-black bg-opacity-90 flex items-center justify-center"
      onClick={onClose}
    >
      {/* Close button */}
      <button
        className="absolute top-4 right-4 z-10 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-70 transition-colors"
        onClick={onClose}
        aria-label="Close lightbox"
      >
        <FiX className="w-6 h-6" />
      </button>

      {/* Image counter */}
      <div className="absolute top-4 left-4 z-10 bg-black bg-opacity-50 text-white px-3 py-1 rounded-full text-sm">
        {currentIndex + 1} / {images.length}
      </div>

      {/* Main image container with touch events */}
      <div
        className="relative w-full h-full flex items-center justify-center"
        onClick={(e) => e.stopPropagation()}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <img
          src={images[currentIndex]?.url}
          alt={`Property image ${currentIndex + 1}`}
          className="max-h-[90vh] max-w-[90vw] object-contain"
        />
      </div>

      {/* Navigation buttons */}
      <button
        className="absolute left-4 z-10 bg-black bg-opacity-50 text-white p-3 rounded-full hover:bg-opacity-70 transition-colors"
        onClick={(e) => {
          e.stopPropagation();
          onNavigate('prev');
        }}
        aria-label="Previous image"
      >
        <FiChevronLeft className="w-6 h-6" />
      </button>

      <button
        className="absolute right-4 z-10 bg-black bg-opacity-50 text-white p-3 rounded-full hover:bg-opacity-70 transition-colors"
        onClick={(e) => {
          e.stopPropagation();
          onNavigate('next');
        }}
        aria-label="Next image"
      >
        <FiChevronRight className="w-6 h-6" />
      </button>
    </div>
  );
};

export default function PropertyDetails({ language }) {
  const navigate = useNavigate()
  const { id } = useParams()
  const [property, setProperty] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [selectedImage, setSelectedImage] = useState(0)
  const [showBookingForm, setShowBookingForm] = useState(false)
  const [coordinates, setCoordinates] = useState(null)
  const { isFavorited, toggleFavorite } = useFavorites(language)
  const isFav = property ? isFavorited(property.id) : false
  const [categories, setCategories] = useState([])
  const [lightboxOpen, setLightboxOpen] = useState(false)
  const { user } = useAuth()

  const t = content[language]

  // Check if user is an owner - we don't show favorites to owners
  const isOwner = user?.type === 'owner'

  // Load Google Maps script
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    language: language === 'ar' ? 'ar' : 'en'
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [propertyResponse, categoriesResponse] = await Promise.all([
          propertyAPI.getPropertyDetails(id),
          propertyAPI.getFeatures()
        ]);

        if (propertyResponse?.data) {
          setProperty(propertyResponse.data);
          // Set coordinates if available
          if (propertyResponse.data.latitude && propertyResponse.data.longitude) {
            setCoordinates({
              lat: parseFloat(propertyResponse.data.latitude),
              lng: parseFloat(propertyResponse.data.longitude)
            });
          }
        }

        if (categoriesResponse?.data) {
          setCategories(categoriesResponse.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message || t.error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, t.error]);

  const handleFavorite = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      toast.error(
        language === 'ar'
          ? 'يجب تسجيل الدخول لإضافة العقار إلى المفضلة'
          : 'Please login to add properties to favorites'
      );
      setTimeout(() => {
        const shouldLogin = window.confirm(
          language === 'ar'
            ? 'هل تريد تسجيل الدخول أو إنشاء حساب جديد؟'
            : 'Would you like to login or create an account?'
        );
        if (shouldLogin) {
          navigate('/login/renter');
        }
      }, 1000);
      return;
    }

    toggleFavorite({ propertyId: property.id, isFavorite: isFav });
  };

  // Handle lightbox navigation
  const handleLightboxNavigate = useCallback((direction) => {
    if (!property?.images?.length) return;

    setSelectedImage(prevIndex => {
      if (direction === 'next') {
        return prevIndex === property.images.length - 1 ? 0 : prevIndex + 1;
      } else {
        return prevIndex === 0 ? property.images.length - 1 : prevIndex - 1;
      }
    });
  }, [property?.images?.length]);

  // Render map section
  const renderMap = () => {
    if (loadError) {
      return (
        <div className="bg-red-50 p-4 rounded-lg">
          <p className="text-red-600 text-sm">
            {language === 'ar'
              ? 'عذراً، حدث خطأ في تحميل الخريطة'
              : 'Sorry, there was an error loading the map'}
          </p>
        </div>
      );
    }

    if (!isLoaded) {
      return (
        <div className="animate-pulse bg-gray-200 h-[300px] sm:h-[400px] rounded-lg" />
      );
    }

    if (!coordinates) {
      return null;
    }

    // Define the custom marker icon
    const propertyIcon = {
      path: "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5z",
      fillColor: "#BE092B",
      fillOpacity: 1,
      strokeWeight: 1.5,
      strokeColor: "#FFFFFF",
      scale: 1.3,
      anchor: new window.google.maps.Point(12, 22)
    };

    return (
      <div className="relative w-full h-[300px] sm:h-[400px] rounded-lg overflow-hidden">
        <GoogleMap
          mapContainerStyle={{
            width: '100%',
            height: '100%'
          }}
          center={coordinates}
          zoom={15}
          options={{
            ...mapOptions,
            scrollwheel: false,
            gestureHandling: "cooperative",
            disableDefaultUI: true,
            zoomControl: true,
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false
          }}
        >
          {coordinates && (
            <MarkerF
              position={coordinates}
              title={language === 'ar' ? 'موقع العقار' : 'Property Location'}
              icon={propertyIcon}
            />
          )}
        </GoogleMap>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="animate-pulse max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="h-96 bg-gray-200 rounded-lg mb-8"></div>
        <div className="h-8 bg-gray-200 rounded w-1/3 mb-4"></div>
        <div className="h-4 bg-gray-200 rounded w-2/3"></div>
      </div>
    )
  }

  if (error || !property) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 text-center">
        <p className="text-red-500">{error || t.error}</p>
      </div>
    )
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6 sm:py-12">
      {/* Back Button */}
      <button
        onClick={() => navigate('/properties/available')}
        className="flex items-center gap-1.5 text-sm text-[#BE092B] hover:text-[#9C0722] mb-4 sm:mb-6 transition-colors"
      >
        <FiArrowLeft className="w-4 h-4" />
        <span>{language === 'ar' ? 'رجوع' : 'Back'}</span>
      </button>

      {/* Image Gallery */}
      <div
        className="relative h-[40vh] sm:h-[60vh] mb-3 sm:mb-4 rounded-lg overflow-hidden bg-gray-100 cursor-pointer group"
        onClick={() => setLightboxOpen(true)}
      >
        <OptimizedImage
          src={property.images?.[selectedImage]?.url || 'https://placehold.co/1200x800?text=No+Image'}
          alt={property.title}
          className="w-full h-full object-cover"
          priority={true}
        />

        {/* Add zoom icon overlay */}
        <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity bg-black bg-opacity-30">
          <div className="bg-white bg-opacity-80 p-3 rounded-full">
            <FiZoomIn className="w-6 h-6 text-gray-800" />
          </div>
        </div>
      </div>

      {/* Thumbnail Images */}
      <div className="grid grid-cols-4 sm:grid-cols-6 gap-2 sm:gap-4 mb-6 sm:mb-8">
        {property.images?.map((image, index) => (
          <button
            key={index}
            onClick={() => {
              setSelectedImage(index);
              setLightboxOpen(true);
            }}
            className={`aspect-square rounded-lg overflow-hidden bg-gray-100 hover:opacity-90 transition-opacity ${selectedImage === index ? 'ring-2 ring-[#BE092B]' : ''}`}
          >
            <OptimizedImage
              src={image.url}
              alt={`${property.title} ${index + 1}`}
              className="w-full h-full object-cover"
              priority={index < 4}
            />
          </button>
        ))}
      </div>

      {/* Main Content */}
      <div className="grid grid-cols-1">
        {/* Property Header Section */}
        <div className="mb-6 sm:mb-8">
          <div className="flex flex-col space-y-3 sm:space-y-4">
            <h1 className="text-2xl sm:text-3xl font-bold text-gray-900">
              {property.title}
            </h1>

            <div className="flex flex-wrap items-center gap-2 text-sm sm:text-base text-gray-600">
              <span className="font-medium text-[#BE092B]">
                {getPropertyType(property.type, language, t)}
              </span>
              {property.type && property.address && <span>•</span>}
              <div className="flex items-center gap-1.5">
                <FiMapPin className="w-4 h-4 text-[#BE092B]" />
                <span>{formatAddress(property.address, language)}</span>
              </div>
            </div>

            <div className="flex items-center justify-between py-3 sm:py-4 border-y border-gray-200">
              <div className="flex items-baseline gap-2">
                <span className="text-2xl sm:text-4xl font-bold text-[#BE092B]">
                  <SARPrice
                    amount={property.price?.toLocaleString(undefined, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    })}
                    showSymbolBefore={language !== 'ar'}
                    fontSize="inherit"
                  />
                </span>
                <span className="text-base sm:text-lg text-gray-600">
                  {language === 'ar' ? 'سنوياً' : '/ year'}
                </span>
              </div>
              {!isOwner && (
                <button
                  onClick={handleFavorite}
                  className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                  aria-label={isFav ? t.removeFromFavorites : t.saveToFavorites}
                >
                  <FiHeart
                    className={`w-5 sm:w-6 h-5 sm:h-6 ${isFav ? 'fill-[#BE092B] text-[#BE092B]' : 'text-gray-600'}`}
                  />
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Key Features */}
        <div className="grid grid-cols-3 sm:grid-cols-4 gap-3 sm:gap-6 mb-6 sm:mb-8">
          {property.number_bedroom > 0 && (
            <div className="flex flex-col items-center p-3 sm:p-4 bg-gray-50 rounded-lg">
              <IoBedOutline className="h-5 w-5 sm:h-6 sm:w-6 text-[#BE092B] mb-2" />
              <span className="font-semibold text-base sm:text-lg">{property.number_bedroom}</span>
              <span className="text-xs sm:text-sm text-gray-600">{t.bedrooms}</span>
            </div>
          )}
          {property.number_bathroom > 0 && (
            <div className="flex flex-col items-center p-3 sm:p-4 bg-gray-50 rounded-lg">
              <IoWaterOutline className="h-5 w-5 sm:h-6 sm:w-6 text-[#BE092B] mb-2" />
              <span className="font-semibold text-base sm:text-lg">{property.number_bathroom}</span>
              <span className="text-xs sm:text-sm text-gray-600">{t.bathrooms}</span>
            </div>
          )}
          {property.area > 0 && (
            <div className="flex flex-col items-center p-3 sm:p-4 bg-gray-50 rounded-lg">
              <FiMaximize className="h-5 w-5 sm:h-6 sm:w-6 text-[#BE092B] mb-2" />
              <span className="font-semibold text-base sm:text-lg">{property.area.toLocaleString()}</span>
              <span className="text-xs sm:text-sm text-gray-600">{t.sqm}</span>
            </div>
          )}
        </div>

        {/* Description */}
        <div className="mb-6 sm:mb-8">
          <h2 className="text-lg sm:text-xl font-semibold mb-3 sm:mb-4">{t.description}</h2>
          <div className="prose prose-gray max-w-none">
            <p className="text-sm sm:text-base text-gray-600 leading-relaxed">{property.description}</p>
          </div>
        </div>

        {/* Features */}
        {categories.map(category => {
          const categoryFeatures = property?.features?.filter(feature => {
            return category.features?.some(f => f.id === feature.id);
          });

          if (!categoryFeatures?.length) return null;

          const categoryName = language === 'en'
            ? (category.type === 'amenities' ? 'Amenities' : 'Additional Features')
            : (category.type === 'amenities' ? 'المرافق' : 'المميزات الإضافية');

          return (
            <div key={category.id} className="mb-6 sm:mb-8">
              <h2 className="text-lg sm:text-xl font-semibold mb-3 sm:mb-4">{categoryName}</h2>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3 sm:gap-4">
                {categoryFeatures.map(feature => {
                  const FeatureIcon = featureIcons[feature.id];
                  const featureName = language === 'en'
                    ? t.features_en[feature.id]
                    : t.features_ar[feature.id];

                  return (
                    <div
                      key={`${category.id}-${feature.id}`}
                      className="flex items-center gap-3 p-4 bg-gray-50 rounded-lg"
                    >
                      {FeatureIcon && <FeatureIcon className="text-[#BE092B] w-5 h-5 flex-shrink-0" />}
                      <span className="text-gray-700">{featureName}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}

        {/* Booking Section */}
        <div className="mb-6 sm:mb-8 bg-white rounded-lg border border-gray-200 p-4 sm:p-6">
          <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 sm:gap-6">
            <div>
              <h3 className="text-base sm:text-lg font-semibold mb-2">
                {language === 'ar' ? 'حجز جولة عقارية' : 'Schedule a Property Tour'}
              </h3>
              <p className="text-xs sm:text-sm text-gray-600">
                {language === 'ar'
                  ? 'احجز جولة لمشاهدة العقار مع أحد مستشارينا العقاريين'
                  : 'Book a tour to view this property with one of our real estate advisors'}
              </p>
            </div>
            <button
              onClick={() => setShowBookingForm(true)}
              className="w-full sm:w-auto flex-shrink-0 bg-[#BE092B] text-white px-6 py-3 rounded-lg font-semibold hover:bg-[#9C0722] transition-colors whitespace-nowrap"
            >
              {t.bookTour}
            </button>
          </div>
        </div>

        {/* Property Location Map */}
        <div className="mb-6 sm:mb-8">
          <h2 className="text-lg sm:text-xl font-semibold mb-3 sm:mb-4">{t.propertyLocation}</h2>
          {renderMap()}
        </div>
      </div>

      {/* Booking Form Modal */}
      {showBookingForm && (
        <BookingForm
          property={property}
          onClose={() => setShowBookingForm(false)}
          language={language}
        />
      )}

      {/* Image Lightbox */}
      {lightboxOpen && property.images?.length > 0 && (
        <ImageLightbox
          images={property.images}
          currentIndex={selectedImage}
          onClose={() => setLightboxOpen(false)}
          onNavigate={handleLightboxNavigate}
        />
      )}
    </div>
  )
} 