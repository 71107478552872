import React from 'react'
import PropTypes from 'prop-types'

/**
 * Component for rendering prices with the Saudi Riyal symbol
 * Uses the @abdulrysr/saudi-riyal-new-symbol-font package
 */
function SARPrice({ amount, className, showSymbolBefore = true, fontSize = 'inherit' }) {
    // Format the amount with commas for thousands separators
    const formattedAmount = typeof amount === 'number'
        ? amount.toLocaleString('en-US')
        : amount

    return (
        <span className={`${className || ''}`} style={{ fontSize, display: 'inline-flex', alignItems: 'center' }}>
            {showSymbolBefore && <span className="icon-saudi_riyal">&#xea;</span>}
            <span style={{ marginLeft: showSymbolBefore ? '0.2em' : '0', marginRight: !showSymbolBefore ? '0.2em' : '0' }}>
                {formattedAmount}
            </span>
            {!showSymbolBefore && <span className="icon-saudi_riyal">&#xea;</span>}
        </span>
    )
}

SARPrice.propTypes = {
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    className: PropTypes.string,
    showSymbolBefore: PropTypes.bool,
    fontSize: PropTypes.string
}

export default SARPrice 