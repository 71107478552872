import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import favoritesAPI from '../services/favoritesAPI'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

export const FAVORITES_QUERY_KEY = ['favorites']

export function useFavorites(language = 'en') {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  // Query for fetching favorites
  const {
    data: favorites = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: FAVORITES_QUERY_KEY,
    queryFn: async () => {
      try {
        const response = await favoritesAPI.getFavorites()
        return response.data || []
      } catch (error) {
        if (error.response?.status === 401) {
          localStorage.removeItem('token')
        }
        throw error
      }
    },
    enabled: !!localStorage.getItem('token'), // Only run if user is logged in
    staleTime: 1000 * 60 * 5, // Consider data fresh for 5 minutes
    cacheTime: 1000 * 60 * 30, // Keep data in cache for 30 minutes
  })

  // Mutation for adding/removing favorites
  const { mutate: toggleFavorite } = useMutation({
    mutationFn: async ({ propertyId, isFavorite }) => {
      if (!localStorage.getItem('token')) {
        throw new Error('unauthorized')
      }
      return isFavorite
        ? favoritesAPI.removeFromFavorites(propertyId)
        : favoritesAPI.addToFavorites(propertyId)
    },
    // Optimistically update the UI
    onMutate: async ({ propertyId, isFavorite }) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: FAVORITES_QUERY_KEY })

      // Snapshot the previous value
      const previousFavorites = queryClient.getQueryData(FAVORITES_QUERY_KEY)

      // Optimistically update to the new value
      queryClient.setQueryData(FAVORITES_QUERY_KEY, (old = []) => {
        if (isFavorite) {
          return old.filter((fav) => fav.id !== propertyId)
        } else {
          // Match your API data structure for favorites
          return [...old, { id: propertyId, unit_id: propertyId }]
        }
      })

      // Return a context object with the snapshotted value
      return { previousFavorites }
    },
    onError: (error, variables, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      queryClient.setQueryData(FAVORITES_QUERY_KEY, context.previousFavorites)

      if (error.message === 'unauthorized') {
        toast.error(
          language === 'ar'
            ? 'يجب تسجيل الدخول لإضافة العقار إلى المفضلة'
            : 'Please login to add properties to favorites'
        )
        setTimeout(() => {
          const shouldLogin = window.confirm(
            language === 'ar'
              ? 'هل تريد تسجيل الدخول أو إنشاء حساب جديد؟'
              : 'Would you like to login or create an account?'
          )
          if (shouldLogin) {
            navigate('/login/renter')
          }
        }, 1000)
      } else if (error.response?.status === 401) {
        localStorage.removeItem('token')
        toast.error(
          language === 'ar'
            ? 'انتهت صلاحية الجلسة. يرجى تسجيل الدخول مرة أخرى'
            : 'Session expired. Please login again'
        )
        setTimeout(() => navigate('/login/renter'), 1500)
      } else {
        toast.error(
          language === 'ar'
            ? 'حدث خطأ أثناء تحديث المفضلة'
            : 'Failed to update favorites'
        )
      }
    },
    onSuccess: (data, { isFavorite }, context) => {
      // Check if the API call was successful based on your API response
      if (data.success) {
        toast.success(
          language === 'ar'
            ? isFavorite
              ? 'تم إزالة العقار من المفضلة'
              : 'تم إضافة العقار إلى المفضلة'
            : isFavorite
            ? 'Property removed from favorites'
            : 'Property added to favorites'
        )
      }
    },
    onSettled: () => {
      // Always refetch after error or success to make sure our optimistic update matches the server state
      queryClient.invalidateQueries({ queryKey: FAVORITES_QUERY_KEY })
    },
  })

  // Helper function to check if a property is favorited
  const isFavorited = (propertyId) => {
    return favorites.some((fav) => fav.id === propertyId || fav.unit_id === propertyId)
  }

  return {
    favorites,
    isLoading,
    error,
    toggleFavorite,
    isFavorited,
  }
} 